/*
 * @Author: your name
 * @Date: 2021-09-26 21:29:54
 * @LastEditTime: 2024-07-27 10:21:18
 * @LastEditors: lbb lhz6887.@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A E
 * @FilePath: \h5-vip\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import "vant/lib/index.css"
import router from './router'
import store from './store/index';

// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// Vue.use(vConsole)

import VueClipboard from 'vue-clipboard2' //引入插件
Vue.use(VueClipboard)

Vue.config.productionTip = false
Vue.use(Vant)


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')