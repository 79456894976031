<!--
 * @Author: your name
 * @Date: 2021-09-26 21:29:54
 * @LastEditTime: 2024-07-27 10:18:07
 * @LastEditors: lbb lhz6887.@163.com
 * @Description: In User Settings Edit
 * @FilePath: \h5-vip\src\App.vue
-->
<template>
  <div id="app">
    <!-- 动画节点 -->
    <div id="loading-mask" v-if="isLoading">
      <div class="loading-wrapper">
        <span class="loading-dot loading-dot-spin"><i></i><i></i><i></i><i></i></span>
      </div>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import{ getLoaing } from "@/api/index"
export default {
  name: 'app',
  data() {
    return {
      isLoading: true
    }
  },
  components: {

  },
 async mounted() {
    await getLoaing({ articleTypes: [15] })
    this.isLoading = false
    
    window.addEventListener('hashchange', () => {
      var currentPath = window.location.hash.slice(1); // 获取输入的路由
      if (this.$router.path !== currentPath) {
        this.$router.push(currentPath); // 动态跳转
      }
    }, false);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  height: 100%;
}

.app-container {
  width: 100vw;
}

#loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  user-select: none;
  z-index: 9999;
  overflow: hidden
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%)
}

.loading-dot {
  animation: antRotate 1.2s infinite linear;
  transform: rotate(45deg);
  position: relative;
  display: inline-block;
  font-size: 64px;
  width: 64px;
  height: 64px;
  box-sizing: border-box
}

.loading-dot i {
  width: 22px;
  height: 22px;
  position: absolute;
  display: block;
  background-color: #d92c2b;
  border-radius: 100%;
  transform: scale(.75);
  transform-origin: 50% 50%;
  opacity: .3;
  animation: antSpinMove 1s infinite linear alternate
}

.loading-dot i:nth-child(1) {
  top: 0;
  left: 0
}

.loading-dot i:nth-child(2) {
  top: 0;
  right: 0;
  -webkit-animation-delay: .4s;
  animation-delay: .4s
}

.loading-dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: .8s;
  animation-delay: .8s
}

.loading-dot i:nth-child(4) {
  bottom: 0;
  left: 0;
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg)
  }
}

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg)
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1
  }
}

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1
  }
}
</style>
