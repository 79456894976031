/*
 * @Author: your name
 * @Date: 2021-09-26 21:29:54
 * @LastEditTime: 2024-07-03 09:38:19
 * @LastEditors: lbb lhz6887@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \h5-vip\src\store\index.js
 */
import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        
    },
    getters: {

	},
    mutations: {

    }
})