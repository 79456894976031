//request.js
import axios from 'axios'
import {
	Toast
} from 'vant'

let url = "https://ips.yhbyf.com" //  线上
// let url = "https://t2.gw.yhbtest.com" //  测试
// let url = "https://uat.yhbzf.com" //  uat


//  如意刷 需要loading加载
const rysRequestloading = axios.create({
	baseURL: 'https://xcx.yhbzf.com/wxapi', //  上线和测试同步
	// baseURL: 'https://uat.yhbzf.com', //  上线和测试同步
	// baseURL: 'https://t2.gw.yhbtest.com', //  上线和测试同步
	// timeout: 100000 // 请求超时时间                                   
})

//  如意刷 小程序
const rysRequestxcx = axios.create({
	baseURL: 'https://wgtran.yhbzf.com:8088/', //  小程序固定
	// baseURL: 'https://t2.gw.yhbtest.com', //  小程序固定
	// timeout: 100000 // 请求超时时间                                   
})

// 开心刷小程序
const kxsRequestxcx = axios.create({
	// baseURL: 'https://t2.gw.yhbtest.com', //  小程序固定
	//baseURL: 'https://uat.yhbzf.com', //uat
	baseURL: 'https://xcx.yhbzf.com/wxapi', //生产
	// baseURL: 'http://120.76.195.104:8083/', //  小程序固定
	// timeout: 100000 // 请求超时时间                                   
})


//不需要loading加载
const request = axios.create({
	baseURL: url,
	// timeout: 100000 // 请求超时时间                                   
})

function startLoading() {
	// 开始加载
	Toast.loading({
		message: '加载中...',
		forbidClick: true,
	});
}

function endLoading() {
	// 结束加载
	Toast.clear()
}

// 如意刷---添加request拦截器 加载loading
rysRequestloading.interceptors.request.use(config => {
	startLoading()
	// config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
	// config.headers['Authorization'] = '39eb9c6f-1ae9-435c-83cf-177f49f4ccd6' // 让每个请求携带自定义token 请根据实际情况自行修改
	return config
}, error => {
	Promise.reject(error)
})

// 如意刷 -- 添加respone拦截器 加载loading
rysRequestloading.interceptors.response.use(
	response => {
		endLoading()
		return Promise.resolve(response.data)
	},
	error => {
		startLoading()
		if (error.response) {
			switch (error.response.status) {
				case 401:
					console.log(401)
					break;
				case 403:
					console.log(403)
					break;
				case 404:
					Toast({
						message: '网络请求不存在',
						duration: 1500,
						forbidClick: true
					})
					console.log(404)
					break;
				case 500:
					console.log(500)
					break;
			}
		}
		return Promise.reject(error.response)
	}
)

// 添加request拦截器 不加载loading
request.interceptors.request.use(config => {
	return config
}, error => {
	Promise.reject(error)
})

// 添加respone拦截器 不加载loading
request.interceptors.response.use(
	response => {
		return Promise.resolve(response.data)
	},
	error => {
		startLoading()
		if (error.response) {
			switch (error.response.status) {
				case 401:
					console.log(401)
					break;
				case 403:
					console.log(403)
					break;
				case 404:
					Toast({
						message: '网络请求不存在',
						duration: 1500,
						forbidClick: true
					})
					console.log(404)
					break;
				case 500:
					console.log(500)
					break;
			}
		}
		return Promise.reject(error.response)
	}
)

// export default request
export let req = request //  没有loading
export let rysReqloading = rysRequestloading // 如意刷
export let rysRequestXcx = rysRequestxcx // 如意刷小程序
export let kxsRequestXcx = kxsRequestxcx // 开心刷小程序
// export let ScRequest = scRequest // 小生产测试