/*
 * @Author: your name
 * @Date: 2021-09-26 21:29:54
 * @LastEditTime: 2024-07-27 10:14:52
 * @LastEditors: lbb lhz6887.@163.com
 * @Description: In User Settings Edit
 * @FilePath: \h5-vip\src\router\index.js
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 路由懒加载
// component: () => import ('文件路径')

const router = new Router({
	mode: 'history', //去掉#号
	routes: [
		// {
		// 	path: "/",
		// 	name: "home",
		// 	meta: {
		// 		title: "加入易伙伴"
		// 	},
		// 	component: () =>
		// 		import('@/pages/home')
		// },
		{
			path: "/lottery",
			name: "Lottery",
			meta: {
				title: "抽奖派红包"
			},
			component: () =>
				import('@/pages/lottery')
		},
		{
			path: "/agreement",
			name: "agreement",
			meta: {
				title: "用户协议"
			},
			component: () =>
				import('@/pages/jcc/agreement.vue')
		}
	]
})

// let arr = []
// router.addRoute(...arr)

router.beforeEach((to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行
	if (to.meta.title) { //判断是否有标题
		document.title = to.meta.title
	}
	const app = router.app;
	if (app) {
		app.$root.isLoading = true;
	}
	next();
})
export default router